<template>
  <v-container fill-height>
    <v-row dense>
      <v-col cols="8" offset="2">
        <v-card>
          <v-card-title class="headline">{{ $t('No Access') }}</v-card-title>
          <v-card-actions class="justify-end">
            <v-btn :to="{ name: 'Login' }" color="primary" text>zum Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'NoAccess',
};
</script>
